import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import TableSubHeader from "../../../components/Table/TableSubHeader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import tableImg from "../../../assets/imgs/User.png";
import InfoIcon from "@material-ui/icons/Info";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import IdCard from "./IdCard/IdCard";
import QrCode from "./IdCard/qr-deenfund.png";
import UserPhoto from "./IdCard/user-photo.jpeg";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import Grid from "@material-ui/core/Grid";
import SelectField from "../../../components/Form/SelectField/SelectField";
import Buttons from "../../../components/Form/Button/Button";

import Modals from "../../../components/hooks/Dialog";
import { useParams } from "react-router-dom";
import {
  useFilterOrderItemsMutation,
  useGetOrderDetailsQuery,
  useUpdaterOrderStatusMutation,
} from "../../../redux/features/order/orderApi";
import { capitalizeFirstLetter } from "../../../components/hooks/HelperFunctions";
import useProtected from "../../../hooks/useProtected";
import Filter from "../../filter/Filter";
import { toast } from "react-toastify";
import axios from "axios";
import { useGetUserTypesQuery } from "../../../redux/features/user_types/userTypesApi";

const Item = () => {
  const { id } = useParams();
  let orderId = id;
  const { data, isLoading, isFetching, error } = useGetOrderDetailsQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateOrderStatus, updateOrderStatusOptions] =
    useUpdaterOrderStatusMutation();
  const [filterOrderItems, filterOrderItemsOptions] =
    useFilterOrderItemsMutation();

  useProtected(error);
  useProtected(updateOrderStatusOptions.error);
  useProtected(filterOrderItemsOptions.error);

  const [filterOpen, setFilterOpen] = useState(false);
  const openFilterHandler = () => {
    setFilterOpen(true);
  };
  const closeFilterHandler = () => {
    setFilterOpen(false);
    setPageNumberFromOutSide(false);
  };

  //=======  Drawer Start ===== //
  const [openDrawer, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [items, setItems] = useState([]);
  const [activeUser, setActiveUser] = useState({});
  const [asc, setAsc] = useState(true);
  const [userTypes, setUserTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [activeUserUniqueId, setActiveUserUniqueId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");

  const [roles, setRoles] = useState([]);

  const {
    data: roleData,
    isError: roleIsError,
    isLoading: roleIsLoading,
    error: roleError,
  } = useGetUserTypesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(roleError);

  useEffect(() => {
    if (!roleIsLoading && !roleIsError) {
      setRoles(roleData?.body?.data);
    }
  }, [roleData, roleIsLoading, roleIsError]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //=======  Drawer End ===== //

  //=======  Change Order Modal ===== //
  const [openChangeOrderModal, setOpenChangeOrderModal] = useState(false);
  const handleOpenChangeOrderModal = () => {
    setOpenChangeOrderModal(true);
  };
  const handleCloseChangeOrderModal = () => {
    setOpenChangeOrderModal(false);
    setOrderStatus("");
  };
  //=======  Change Order Modal  ===== //

  const onClick = () => {
    alert("onclick working fine!");
  };
  ///=================================== sorting part start =============//
  const ascendingOrDescendingSortingByUserType = (field) => {
    let sortedUsers = [...items];
    if (asc) {
      sortedUsers.sort((a, b) => a[field]?.localeCompare(b[field]));
    } else {
      sortedUsers.sort((a, b) => b[field]?.localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc);
  };
  const ascendingOrDescendingSortingByStatus = (field) => {
    let sortedUsers = [...items];
    if (asc) {
      sortedUsers.sort((a, b) => a[field]?.localeCompare(b[field]));
    } else {
      sortedUsers.sort((a, b) => b[field]?.localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc);
  };
  ///=================================== sorting part end =============//

  const headCells = [
    {
      id: "name",
      numeric: true,
      disablePadding: false,
      label: "Name",
      width: "20%",
    },
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: "ID",
      width: "12%",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "User type",
      width: "12%",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByUserType("userType"),
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "QR",
      width: "10%",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "ID Photo",
      width: "20%",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      width: "15%",
      align: "right",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSortingByStatus("status"),
    },
    { id: "", numeric: true, disablePadding: false, label: "", width: "10%" },
  ];

  const [age, setAge] = React.useState("");
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Pending",
      value: "pending",
    },

    {
      label: "Completed",
      value: "completed",
    },
    {
      label: "Canceled",
      value: "cancelled",
    },
  ];

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setRows(convertDataToCellRows(data?.body?.data));
      setItems(data?.body?.data);
    }
  }, [data, isLoading, isFetching, error]);

  const statusModalHandler = (id, userUniqueID, statusLabel) => {
    setActiveId(id);
    setActiveUserUniqueId(userUniqueID);
    handleOpenChangeOrderModal();
    setOrderStatus(statusLabel?.toLowerCase());
  };

  const changeOrderStatusHandler = (e) => {
    setOrderStatus(e.target.value);
  };

  const changeStatusHandler = async () => {
    let data = {
      userUniqueID: activeUserUniqueId,
      status: orderStatus,
    };
    const result = await updateOrderStatus({ ...data, id });
    //if response status is 200
    if (!result?.data?.error) {
      toast.success(result.data.message);
      // setOpenEditMedia(false);
    } else {
      toast.error(result.data.message);
    }

    setActiveId("");
    setActiveUserUniqueId("");
    setOrderStatus("pending");

    handleCloseChangeOrderModal();
  };

  const showIdDetails = (userId) => {
    let foundItem = data?.body?.data?.find((item) => item._id === userId);
    setActiveUser(foundItem);
    handleDrawerOpen();
  };
  const convertDataToCellRows = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => {
        let statusBackground = "";
        let statusColor = "";
        let statusLineBackground = "";
        let statusLabel = "";
        if (item?.status === "pending") {
          statusBackground = "#FFF5E6";
          statusColor = "#EEB000";
          statusLineBackground = "EEB000";
          statusLabel = "Pending";
        } else if (item?.status === "cancelled") {
          statusBackground = "#FCE7E9";
          statusColor = "#E63946";
          statusLineBackground = "BA1A1A";
          statusLabel = "Cancelled";
        } else if (item?.status === "completed") {
          statusBackground = "#EDF7EA";
          statusColor = "#41AB37";
          statusLineBackground = "41AB37";
          statusLabel = "Completed";
        }

        let idPictureCellInfo =
          item?.idPicture && item?.idPicture != ""
            ? {
                img:
                  item?.idPicture && item?.idPicture != ""
                    ? item?.idPicture
                    : tableImg,
                imgStatus: true,
                text:
                  item?.idPicture &&
                  item?.idPicture !== "" &&
                  item?.idPictureName &&
                  item?.idPictureName !== ""
                    ? item?.idPictureName
                    : "N/A",
                align: "left",
              }
            : {
                StatusIcon:
                  item?.idPicture && item?.idPicture !== "" ? (
                    <CheckCircleIcon />
                  ) : (
                    <span style={{ display: "flex", marginRight: "4px" }}>
                      <ErrorIcon />
                    </span>
                  ),
                Icon: true,
                IconColor:
                  item?.idPicture && item?.idPicture !== ""
                    ? "#41AB37"
                    : "#FFB606",
                date:
                  item?.idPicture && item?.idPicture !== "" ? "" : "Missing",
              };
        return {
          cell: [
            {
              Check: true,
              width: "48px",
              padding: "0",
              user_id: item._id,
              checked: true,
            },
            {
              linkText:
                item?.firstName !== "" && item?.lastName !== ""
                  ? `${item?.firstName} ${item?.lastName}`
                  : "N/A",
              linkTextStatus: true,
              link: "#",
            },
            { text: item?.userUniqueId !== "" ? item?.userUniqueID : "N/A" },
            {
              text:
                item?.userType !== ""
                  ? capitalizeFirstLetter(item?.userType)
                  : "N/A",
            },
            {
              StatusIcon:
                item?.qrCode && item?.qrCode != "" ? (
                  <CheckCircleIcon />
                ) : (
                  <span style={{ display: "flex", marginRight: "4px" }}>
                    <ErrorIcon />
                  </span>
                ),
              Icon: true,
              IconColor:
                item?.qrCode && item?.qrCode !== "" ? "#41AB37" : "#FFB606",
              date: item?.qrCode && item?.qrCode !== "" ? "" : "Missing",
            },

            idPictureCellInfo,
            {
              Status: true,
              background: statusBackground,
              color: statusColor,
              linebackground: statusLineBackground,
              statusLine: false,
              label: statusLabel,
            },
            { ActionButtonDotIcon: true },
          ],
          //======= Action Date Start===== //
          action: [
            { label: "Details", onClick: (e) => showIdDetails(item?._id) },
            {
              label: "Change Status",
              onClick: (e) =>
                statusModalHandler(item?._id, item?.userUniqueID, statusLabel),
            },
          ],
          //======= Action Date End===== //
        };
      });
    } else {
      return [];
    }
  };

  const changeStatusCancelHandler = () => {
    setActiveId("");
    setActiveUserUniqueId("");
    setOrderStatus("pending");

    handleCloseChangeOrderModal();
  };

  const downloadIdCardHandler = async () => {
    let data = JSON.stringify({
      firstName: activeUser?.firstName,
      lastName: activeUser?.lastName,
      userType: activeUser?.userType,
      qrCode: activeUser?.qrCode,
      userUniqueID: activeUser?.userUniqueID,
      studentInfo:
        activeUser?.userType == "student" &&
        activeUser?.graduationLevel &&
        activeUser?.graduationYear
          ? `${
              activeUser?.graduationLevel
            } CLASS OF ‘${activeUser?.graduationYear?.slice(-2)}`
          : "",
      idPhoto: activeUser?.idPicture,
    });

    fetch(
      "https://hudhud-api.deenfund.com/deenschool-id-card-prod/screenshot",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Ensure this line is correctly executed
        }
        throw new Error("Network response was not ok.");
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${
          activeUser?.userUniqueID
        }-${activeUser?.firstName.toLowerCase()}-${activeUser?.lastName.toLowerCase()}.png`; // or whatever your file name is
        document.body.appendChild(a); // Required for Firefox
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error:", error));
  };

  const filterHandler = async () => {
    setPageNumberFromOutSide(true);
    let orderId = id;
    let dataToSubmit = {
      id: orderId,
      userType: userTypes,
      searchTerm: "",
    };

    const result = await filterOrderItems(dataToSubmit);

    console.log({ result });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
    closeFilterHandler();

    // setIsSearchResult(true)
  };
  const handleCancelFilterDrawer = async () => {
    closeFilterHandler();
    setUserTypes([]);
    setRows(convertDataToCellRows(items));
  };

  //================= search part start =========================//

  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setTimeout(async () => {
        await handleResetSearch();
      }, 200);
      setResetSearchTerm(false);
    }
  }, [resetsearchTerm]);

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };
  const handleResetSearch = async () => {
    let dataToSubmit = {
      id: orderId,
      userType: roles?.map((user) => user?.name),
      searchTerm: "",
    };
    setPageNumberFromOutSide(true);
    const result = await filterOrderItems(dataToSubmit);
    searchDataShowing(result);
  };

  const handleSearch = async () => {
    let dataToSubmit = {
      id: orderId,
      userType: roles?.map((user) => user?.name),
      searchTerm: searchTerm,
    };
    setPageNumberFromOutSide(true);
    const result = await filterOrderItems(dataToSubmit);
    searchDataShowing(result);
  };

  const searchDataShowing = (result) => {
    if (!result?.data?.error) {
      setRows(convertDataToCellRows(result?.data?.body?.data));
    } else {
      toast.error("Something went wrong");
    }
    setPageNumberFromOutSide(false);
  };
  //================= search part end =========================//]
  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setUsers((prevUsers) => [...prevUsers, item[1].user?._id]);
    } else {
      // Remove the user from the array
      setUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== item[1].user?._id)
      );
    }
  };

  return (
    <>
      <div className="TableSection">
        <TableSubHeader
          TableTitle="Items"
          //== Search Code == //
          Search={true}
          SearchPlaceholder="Search..."
          SearchOnChange={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          // SearchOnclick={SearchOnclick}
          // SearchOnMouseDown={SearchOnMouseDown}

          filterCountStatus={userTypes?.length > 0}
          filterCountNumber={userTypes?.length}
          Filter={true}
          FilterOnClick={(e) => openFilterHandler()}
        />
        <Table
          headCells={headCells}
          rows={rows}
          AddButton={true}
          footer={true}
          handleRowClick={handleRowClick}
          CheckboxDelete={true}
          customCellObjectId="user_id"
          handleCheckItem={setUsers}
          selectedCheckList={users}
          RowsPerPage={10}
          isDataLoading={isLoading || filterOrderItemsOptions.isLoading}
          filterCountStatus={userTypes?.length > 0}
          filterCountNumber={userTypes?.length}
          pageNumberFromOutSide={pageNumberFromOutSide}
        />
      </div>

      {/* Modal Change Order Status Start*/}
      <Modals
        openDialog={openChangeOrderModal}
        handleCloseDialog={handleCloseChangeOrderModal}
        title="Change Order Status"
        textAlign="center"
        body={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectField
                  label="Select Status"
                  selectedOption={orderStatus}
                  FieldLabel={true}
                  options={options}
                  handleChange={(e) => changeOrderStatusHandler(e)}
                />
              </Grid>

              <Grid item xs={12}>
                <Buttons
                  label="Save"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  //onClick={ }
                  onClick={(e) => changeStatusHandler()}
                />
              </Grid>

              <Grid item xs={12}>
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#0450E1"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  onClick={(e) => changeStatusCancelHandler()}
                />
              </Grid>
            </Grid>
          </div>
        }
      />
      {/* Modal Change Order Status End*/}

      {/* Drawer Start*/}
      <SideDrawer
        title="ID Details"
        ButtonLabel="Download"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        FooterWithoutTab={true}
        clickSaveButton={(e) => downloadIdCardHandler()}
        body={
          <>
            <div>
              <div>
                <IdCard
                  UserPhoto={
                    activeUser?.idPicture && activeUser?.idPicture != ""
                      ? activeUser?.idPicture
                      : "N/A"
                  }
                  FirstName={
                    activeUser?.firstName
                      ? capitalizeFirstLetter(activeUser?.firstName)
                      : "N/A"
                  }
                  LastName={
                    activeUser?.lastName
                      ? capitalizeFirstLetter(activeUser?.lastName)
                      : "N/A"
                  }
                  Designation={
                    activeUser?.userType ? activeUser?.userType : "N/A"
                  }
                  OrgNameStatus={true}
                  OrgName={
                    activeUser?.userType == "student" &&
                    activeUser?.graduationLevel &&
                    activeUser?.graduationYear
                      ? `${
                          activeUser?.graduationLevel
                        } CLASS OF ‘${activeUser?.graduationYear?.slice(-2)}`
                      : ""
                  }
                  IdNumber={
                    activeUser?.userUniqueID ? activeUser?.userUniqueID : "N/A"
                  }
                  QrCode={activeUser?.qrCode ? activeUser?.qrCode : "N/A"}
                  StatusColor="#64BC08"
                />
              </div>
            </div>
            {/* <div className="DownloadButtonContainer">
              <Button className="CardDownloadButton">Download</Button>
            </div> */}
          </>
        }
      />
      {/* Drawer End*/}

      {/* Filter */}
      <SideDrawer
        open={filterOpen}
        handleDrawerClose={closeFilterHandler}
        handleDrawerCloseButtonBottom={handleCancelFilterDrawer}
        title="Filter"
        buttonLabel="Apply"
        sideOpen="right"
        ButtonLabel="Apply"
        cancelButtonLabel="Clear Filters"
        FooterWithoutTab={true}
        clickSaveButton={(e) => filterHandler()}
        body={
          <>
            <Filter
              label="Status"
              userTypes={userTypes}
              setUserTypes={setUserTypes}
              roles={roles}
            />
          </>
        }
      />
    </>
  );
};

export default Item;
